<template>
  <div id="app">
    <TheHeader />
    <full-page ref="fullpage" :options="options" id="fullpage">
      <SectionSlide class="section" @moveToNext="$refs.fullpage.api.moveSectionDown()">
        <div class="animated-text-wrapper">
          <span class="animated-text animate__animated animate__fadeInUpBig animate__delay-1s">
            With so MUCH DISRUPTION IN
            <br v-if="isMobile">
            THE FINANCIAL
            <br v-if="isDesktop">
            INDUSTRY,
            <br v-if="isMobile">
            HOW DO BRANDS CONNECT
            <br>
            WITH THEIR AUDIENCE?
            <br v-if="isMobile">
            CREATE NEW MARKETS?
            <br>
            AND GROW THEIR BUSINESS?
          </span>
        </div>
        <div class="bg-video">
          <iframe
            src="https://player.vimeo.com/video/664982164?h=dd6d658600&background=1"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </SectionSlide>
      <SectionSlide class="section" @moveToNext="$refs.fullpage.api.moveSectionDown()">
        <div class="animated-text-wrapper">
          <span class="animated-text animate__delay-1s">
            WE BRING OVER
            <br v-if="isMobile">
            70 YEARS OF COMBINED
            <br v-if="isMobile">
            EXPERIENCE
            <br v-if="isDesktop">
            WORKING
            <br v-if="isMobile">
            IN FINANCIAL SERVICES.
          </span>
        </div>
        <div class="bg-video">
          <iframe
            src="https://player.vimeo.com/video/664982072?h=ea96d8f245&background=1"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </SectionSlide>
      <SectionSlide class="section" @moveToNext="$refs.fullpage.api.moveSectionDown()">
        <div class="animated-text-wrapper">
          <span class="animated-text animate__delay-1s">
            WE'VE DEVELOPED
            <br v-if="isMobile">
            WORK
            <br v-if="!isMobile">
            FOR LEADING
            <br v-if="isMobile">
            BRANDS
            <br v-if="!isMobile">
            AND HELPED
            <br v-if="isMobile">
            CREATE NEW ONES.
          </span>
        </div>
        <div class="bg-video">
          <iframe
            src="https://player.vimeo.com/video/664982293?h=28fb6b6108&background=1"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </SectionSlide>
      <SectionSlide class="section" @moveToNext="$refs.fullpage.api.moveSectionDown()">
        <div class="animated-text-wrapper">
          <span class="animated-text animate__delay-1s">
            We work across
            <br v-if="isMobile">
            all parts
            <br v-if="isDesktop">
            of the
            <br v-if="isMobile">
            marketing funnel
            <br>
            and create work
            <br v-if="isMobile">
            for all platforms.
          </span>
        </div>
        <div class="bg-video">
          <iframe
            src="https://player.vimeo.com/video/664981772?h=73eead5ca5&background=1"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </SectionSlide>
      <SectionSlide is-last-slide class="section">
        <span class="animated-text animate__delay-1s">
          AND WE BRING
          <br>
          A MORE EFFICIENT
          <br v-if="isMobile">
          APPROACH
          <br>
          TO DEVELOPING
          <br>
          MARKETING CONTENT.
          <br>
        </span>
        <div class="text-divider animated-text animate__delay-1s"></div>
        <span class="slide-text-smaller animated-text animate__delay-1s">
          BRAND STRATEGY
          <br>
          DESIGN
          <br>
          CREATIVE DEVELOPMENT
          <br>
          PRODUCTION & EDITORIAL
        </span>
        <a href="https://tableofcontent.com/" class="slide-link animated-text animate__delay-2s">
          Let's get started.
        </a>
        <div class="bg-video">
          <iframe
            src="https://player.vimeo.com/video/664981883?h=86c745f950&background=1"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </SectionSlide>
    </full-page>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';
import SectionSlide from '@/components/SectionSlide.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    SectionSlide,
  },
  data() {
    return {
      options: {
        licenseKey: '440539E2-72094E17-BD76EDD9-33780CED',
        navigation: true,
        afterRender: this.afterRender,
        onLeave: this.onLeave,
        scrollingSpeed: 1400,
      },
      isActiveSlideLast: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
    isTablet() {
      return window.innerWidth > 600 && window.innerWidth <= 960;
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
  },
  methods: {
    onLeave(origin, destination) {
      this.isActiveSlideLast = destination.isLast;

      this.adjustSlideVideoSize(origin.item, destination.item);

      const orgTextElements = origin.item.querySelectorAll('.animated-text');
      const destTextElements = destination.item.querySelectorAll('.animated-text');

      origin.item.classList.add('animate__animated', 'animate__zoomOut', 'animate__slower');

      setTimeout(() => {
        origin.item.classList.remove(
          'animate__animated',
          'animate__zoomOut',
          'animate__slower',
        );
      }, this.options.scrollingSpeed);

      if (!orgTextElements?.length && !destTextElements?.length) return;

      orgTextElements.forEach((item) => {
        item.classList.remove('animate__animated', 'animate__fadeInUpBig');
      });

      destTextElements.forEach((item) => {
        item.classList.add('animate__animated', 'animate__fadeInUpBig');
      });
    },
    adjustSlideVideoSize(prevSlideNode, nexSlideNode) {
      const prevSlideIframe = prevSlideNode.querySelector('iframe');
      const nextSlideIframe = nexSlideNode.querySelector('iframe');

      prevSlideIframe.style.cssText = '';
      nextSlideIframe.style.cssText = 'height: 100vw';
    },
    afterRender() {
      const firstSlideIframe = document.querySelector('iframe');
      firstSlideIframe.style.cssText = 'height: 100vw';
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/mixins.scss";

.section {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;

  @include tablet-min {
    font-size: 35px;
    line-height: 50px;
  }

  @include desktop-min {
    font-size: 25px;
    line-height: 38px;
  }

  @include desktop-4k-min {
    font-size: 42px;
    line-height: 61px;
  }

  .fp-tableCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 2000px;
    margin: 0 auto;
  }

  .bg-video {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: var(--primary-background-color-transparent);

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);

      @media (max-aspect-ratio: 16 / 9) {
        width: 177.77777778vh; // aspect-ratio hack for safari < v14
      }
    }
  }

  .animated-text-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 600px;
    height: 100%;

    @include mobile-max {
      min-width: 100vw;
    }

    span {
      width: 100%;
    }
  }

  .text-divider {
    z-index: 1;
    width: 70px;
    height: 1px;
    margin: 22px auto;
    background-color: var(--primary-color);
  }

  .slide-text-smaller {
    font-size: 14px;
    line-height: 25px;

    @include tablet-min {
      font-size: 22px;
      line-height: 45px;
    }

    @include desktop-min {
      font-size: 18px;
      line-height: 35px;
    }

    @include desktop-4k-min {
      font-size: 23px;
      line-height: 46px;
    }
  }

  .slide-link {
    display: block;
    margin-top: 65px;
    padding: 20px 25px;
    color: var(--contrast-text-color);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    background-color: var(--primary-color);
    mix-blend-mode: screen;

    @include tablet-min {
      margin-top: 65px;
      padding: 25px 40px;
      font-size: 22px;
    }

    @include desktop-min {
      margin-top: 50px;
      padding: 20px 30px;
      font-size: 18px;
    }

    @include desktop-4k-min {
      margin-top: 170px;
      padding: 25px 40px;
      font-size: 23px;
    }
  }
}
</style>
