<template>
  <header class="header">
    <a href="https://www.tableofcontent.com/">
      <img src="/img/logo.png" class="logo" alt="table of content">
    </a>
    <h1 class="header-title">FINANCIAL SERVICES</h1>
    <a href="https://www.tableofcontent.com/" class="menu-btn" >
      <img src="/img/menu-icon.svg" alt="">
    </a>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data() {
    return {
      isMenuActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  padding: 19px;
  color: var(--text-color);
  background-color: var(--primary-background-color);

  @include tablet-min {
    padding: 46px 52px;
  }

  @include desktop-min {
    padding: 24px;
  }

  @include desktop-4k-min {
    padding: 32px;
  }

  .logo {
    width: 55px;

    @include tablet-min {
      width: 150px;
    }

    @include desktop-min {
      width: 75px;
    }

    @include desktop-4k-min {
      width: 105px;
    }
  }

  .header-title {
    align-self: flex-end;
    margin-bottom: -2px;
    margin-left: -25px;
    font-size: 10px;
    line-height: 1;

    @include tablet-min {
      font-size: 15px;
    }

    @include desktop-min {
      font-size: 12px;
    }

    @include desktop-4k-min {
      font-size: 18px;
    }
  }

  .menu-btn {
    z-index: 100;
    align-self: flex-start;
    width: 28px;
    margin-left: auto;

    @include tablet-min {
      width: 68px;
    }

    @include desktop-min {
      width: 30px;
    }

    @include desktop-4k-min {
      width: 43px;
    }
  }
}
</style>
