<template>
  <div class="section-slide">
    <slot />
    <button v-if="!isLastSlide" class="slide-arrow-btn" @click="$emit('moveToNext')">
      <svg width="328" height="46" viewBox="0 0 328 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M164 46L0 7.0582L1.68824 0L164 38.455L326.312 0L328 7.0582L164 46Z" fill="#fff"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SectionSlide',
  props: {
    isLastSlide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.section-slide {
  position: relative;
  padding-right: 44px;
  padding-left: 44px;
  color: var(--text-color);
  text-align: center;
  text-transform: uppercase;
  background: #5E5E5E;
  mix-blend-mode: multiply;

  &::before {
    position: absolute;
    display: block;
    opacity: 1;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: url("/img/crt-screen.gif") repeat;
    -webkit-transition: opacity ease-out .4s;
    transition: opacity ease-out .4s;
  }

  .slide-arrow-btn {
    position: absolute;
    left: 50%;
    bottom: 10%;
    display: flex;
    justify-content: center;
    width: 20%;
    transform: translate(-50%, -50%);

    @include tablet-min {
      bottom: 10%;
    }

    @include desktop-min {
      width: 6%;
    }
  }
}
</style>
